import React from 'react';

interface LayoutProps {
  children?: JSX.Element | JSX.Element[];
  bg?: string;
}

const Layout = ({ children, bg }: LayoutProps): JSX.Element => {
  return (
    <main
      className={`min-h-full min-w-full flex items-center justify-center py-6 md:py-12 px-0 sm:px-4 lg:px-8 ${
        bg || 'bg-lightgrey-100'
      } flex-col`}
    >
      {children}
    </main>
  );
};

export default Layout;
