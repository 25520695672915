export const getOnUserSupplierInvitationResponseParse = (data: BDUserInvitation): UserInvitation => ({
  id: data.id,
  phone: data.phone,
  email: data.email,
  firstName: data.first_name,
  lastName: data.last_name,
  supplierUserId: data.supplier_user_id,
  supplierId: data.supplier_id,
  role: data.role,
  status: data.status,
  createdAt: data.created_at,
  updatedAt: data.updated_at,
});
